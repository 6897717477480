import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1454789548928-9efd52dc4031?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=632&q=80",
      subtitle: "0% FEE SOLANA STAKING",
      title: "The Mission",
      descriptions: [
        "At Chive, our mission is to empower individuals to achieve passive income through staking.",
        "Staking SOL tokens into our validator lets you earn top staking rewards as our performance-optimized validator software is consistently ranked at the top in terms of APY. Furthermore, we do 0% fee: all staking rewards directly go into your wallet.",
      ],
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1582139329536-e7284fece509?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
      subtitle: "YOUR SECURITY IS OUR UPPERMOST CARE",
      title: "The Security",
      descriptions: [
        "Staking with Chive is considered a zero risk endeavor. When you stake your SOL tokens with us, you are simply granting us the voting power of your tokens. We do not have the authority to transfer your SOL tokens.",
        "Reliability is of the utmost importance to us. Our staking servers are duplicated across three different data centers from three different providers globally (Teraswitch, AWS, and OVH) to ensure maximum uptime. We also employ 24/7 active monitoring via Pager Duty, and in the event of a validator failure, our validator will switch to a standby server located in another data center to ensure continuity of service.",
        "We employ a number of measures to safeguard your assets. Our validator servers are firewalled with minimal ports open to the public internet. Access to the servers is only possible through the use of SSH keys protected by hardware security. This ensures that your assets are safe and secure at all times.",
      ],
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1504328345606-18bbc8c9d7d1?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
      subtitle: "FAITH IN FINANCIAL INCLUSION AND FREEDOM",
      title: "The Team",
      descriptions: [
        "Thanks to the advent of Web 3.0, financial activities on the blockchain are now more transparent than ever before. For the first time in human history, individuals have the opportunity to achieve 100% financial inclusion and freedom. At Chive Staking, we are a team of tech-savvy individuals who are dedicated to building a brighter future for financial inclusion and freedom. Our staking service is the first step in this journey, and we are committed to providing our clients with the tools and resources they need to take control of their financial well-being.",
        "We have an experienced team with a wealth of knowledge in various fields. Our team members have an average of 8 years of experience in Rust, 6 years of experience in the cryptocurrency industry, and 2 years of experience focused specifically with the Solana blockchain. ",
      ],
    },
  ];

  return (
    <Container id="about-us">
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>About Us</HeadingTitle>
          <HeadingDescription>Learn about Chive's mission, security and team</HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                {card.descriptions.map((el) => (
                  <Description>{el}</Description>
                ))}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Why Zero Fee</HeadingTitle>
        </HeadingInfoContainer>

        <Content>
          <Card key={0} reversed={false}>
            <Details>
              <Description>
                {
                  "You might be wondering why Chive offers a 0% fee forever. Could it be a fraud? Ok, here is why:"
                }
              </Description>
              <Description>
                {
                  "First, we the team members are strong believers in the Solana blockchain and self-staked to Chive's validator. To earn a higher APY, we have taken steps to become a high-performing validator, and we want to share our validator with others."
                }
              </Description>
              <Description>
                {
                  "Secondly, and more importantly, we aim to build the Chive brand within the Solana community and the wider crypto community. Offering a 0% fee validator is a pioneering service we provide to the community in order to attract attention, showcase our offerings, and advertise our brand."
                }
              </Description>
              <Description>
                {
                  "We hope this clears up any confusion and encourages you to give our validator a try."
                }
              </Description>
            </Details>
          </Card>
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
