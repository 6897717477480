import React from "react";
import tw from "twin.macro";
import { Link } from "react-scroll";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-3/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const NavLink = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

// eslint-disable-next-line
export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Chive Staking</LogoText>
          </LogoContainer>
          <CompanyDescription>
            Chive is a staking-as-a-service company that specializes in providing staking services
            on the Solana blockchain.
          </CompanyDescription>
          <CompanyDescription>Built with lots of ☕</CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <NavLink>
                <Link to="home" smooth={true}>
                  Home
                </Link>
              </NavLink>
            </LinkListItem>
            <LinkListItem>
              <NavLink>
                <Link to="about-us" smooth={true}>
                  About Us
                </Link>
              </NavLink>
            </LinkListItem>
            <LinkListItem>
              <NavLink>
                <Link to="contact-us" smooth={true}>
                  Contact Us
                </Link>
              </NavLink>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Stake Link</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <NavLink
                href="https://staking.kiwi/app/Ev3gPXRo6TJeQ2QhxfqWFoasdoGbmDytYvJbUabEHfLf"
                target="_blank"
              >
                Staking.kiwi
              </NavLink>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
