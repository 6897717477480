import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/SimpleFiveColumn.js";

// eslint-disable-next-line
export default () => (
  <AnimationRevealPage>
    <Hero />
    <Features />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
